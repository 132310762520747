import {Controller} from "@hotwired/stimulus"
// import ContentLoader from "stimulus-content-loader"
import {get, patch} from "@rails/request.js"
import * as bootstrap from "bootstrap";

// Connects to data-controller="containers"
export default class extends Controller {
  static values = {url: String, entities: Array}
  entities = []
  popoverButtons = []

  connect() {
    if (!location.search) location.search = "?staff_unassigned=false"

    this.popoverButtons = document.querySelectorAll('[data-bs-toggle="popover"]')
    this.tooltips = document.querySelectorAll('[data-bs-toggle="tooltip"]')

    const popoverTriggerList = [].slice.call(this.popoverButtons)
    const tooltipTriggerList = [].slice.call(this.tooltips)

    this.popoverList = popoverTriggerList.map((popover) => {
      return new bootstrap.Popover(popover, {})
    })
    this.tooltipList = tooltipTriggerList.map((tooltip) => {
      return new bootstrap.Tooltip(tooltip, {})
    })
  }

  disconnect() {
    super.disconnect();
  }

  togglePopover(event) {
    let clickedElement = event.target
    if (clickedElement.localName === "div") clickedElement = event.target.parentNode
    else if (clickedElement.localName === "img") clickedElement = event.target.parentNode.parentNode

    const popover = this.popoverList.find((p) => {
      return p.tip && p._element !== clickedElement
    })

    if (popover) popover.hide()
  }

  async changeTab(event) {
    event.preventDefault()

    try {
      const {search, url, searchValue, options} = event.params
      const destinationUrl = new URL(url)

      if (!destinationUrl.searchParams.has(`${search}_unassigned`))
        url.searchParams.append(`${search}_unassigned`, searchValue)
      else
        destinationUrl.searchParams.set(`${search}_unassigned`, searchValue)

      // if (options) {
      //   if (!destinationUrl.searchParams.has("options")) url.searchParams.append("options", options)
      //   else destinationUrl.searchParams.set("options", options)
      // }

      const {ok} = await get(destinationUrl, {responseKind: "turbo-stream"})

      if (ok) history.pushState({data_turbo_history: "replace"}, {}, destinationUrl)

      // history.pushState({ data_turbo_history: "replace" }, {}, destinationUrl)
    } catch (e) {
      console.error({e})
    }
  }

  toggleAllStaff(event) {
    event.preventDefault()
    const users = document.getElementsByClassName("user-checkbox")

    for (let i = 0; i < users.length; i++) {
      users[i].checked = event.target.checked
    }
  }

  toggleAllEntities(event) {
    event.preventDefault()
    const entities = document.getElementsByClassName("entity-checkbox")

    for (let i = 0; i < entities.length; i++) {
      const entity = entities[i]
      const changeEvent = new Event('change')
      entity.checked = event.target.checked
      entity.dispatchEvent(changeEvent)
    }
  }

  async toggleUnassigned(event) {
    event.preventDefault()

    try {
      location.search = `${event.params.search}_unassigned=${event.target.checked}`
      history.pushState({data_turbo_history: "replace"}, {}, location.href)
    } catch (e) {
      console.error({e})
    }
  }

  async toggleColumn(event) {
    event.preventDefault()

    try {
      const {normId} = await event.params
      const checkboxes = document.getElementsByClassName(`entity-norm-checkbox-${normId}`)
      if (checkboxes.length) {
        for (let i = 0; i < checkboxes.length; i++) {
          const checkbox = checkboxes[i]
          if (checkbox && !checkbox.disabled) {
            checkbox.value = event.target.checked ? normId : null
            checkbox.checked = event.target.checked
          }
        }
      }
    } catch (e) {
      console.error({e})
    }
  }

  async toggleRow(event) {
    event.preventDefault()

    try {
      const {row, containerNormIds} = await event.params
      const checkboxes = document.getElementsByClassName(`${row}-checkbox`)

      if (checkboxes.length) {
        for (let i = 0; i < checkboxes.length; i++) {
          const checkbox = checkboxes[i]

          const index = containerNormIds.findIndex((normId) => Number(checkbox.value) === normId)

          if (checkbox) {
            if (event.target.checked) {
              checkbox.disabled = !(index !== -1) || !event.target.checked
            } else {
              checkbox.disabled = true
              checkbox.checked = false
              // if (!checkbox.checked) checkbox.checked = false
              // else checkbox.checked = true
            }
          }
        }
      }
    } catch (e) {
      console.error({e})
    }
  }

  async toggleContainerNorm(event) {
    // event.preventDefault()

    try {
      const {checked} = event.target
      const {locale, companyId, containerId, entityId, normId, normExists, defaultParams} = event.params

      if (!containerId) return

      const {entities_by_norm_unassigned, entities_unassigned, staff_unassigned, options, connected} = defaultParams

      let url = `/${locale}/companies/${companyId}/containers/${containerId}/`
      let query = new URLSearchParams()

      if (!!entities_by_norm_unassigned) query.set("entities_by_norm_unassigned", entities_by_norm_unassigned)
      if (!!entities_unassigned) query.set("entities_unassigned", entities_unassigned)
      if (!!staff_unassigned) query.set("staff_unassigned", staff_unassigned)
      if (!!options) query.set("options", options)
      if (!!connected) query.set("connected", connected)

      if (entityId) url += `entities/${entityId}/`
      if (normId) url += `norms/${normId}`
      url += normExists ? '/unassign' : '/assign'

      const {ok} = await get(`${url}?${query}`, {responseKind: "turbo-stream"})

      if (ok) {
        const mainCheckbox = document.getElementsByClassName(`set_all_norm_${normId}`)[0]
        if (mainCheckbox) {
          mainCheckbox.disabled = !checked
          const checkboxes = document.getElementsByClassName(`entity-norm-checkbox-${normId}`)
          if (checkboxes.length) {
            for (let i = 0; i < checkboxes.length; i++) {
              const checkbox = checkboxes[i]
              if (checkbox) checkbox.disabled = mainCheckbox.disabled
            }
          }
        }
      }
    } catch (e) {
      console.error({e})
    }
  }

  applyChanges(event) {
    const {close} = event.params
    const click = new Event('click')
    const buttons = document.querySelectorAll("button.apply-changes-button")

    if (!close) event.preventDefault()

    if (buttons && buttons.length) {
      if (click) {
        buttons.forEach((button) => {
          button.dispatchEvent(click)
        })
      }
    }
  }

  async submit(event) {
    if (!event) return

    event.preventDefault();
    const {form} = event.target
    const {action} = form
    const data = new FormData(form)

    try {
      await patch(action, {responseKind: "turbo-stream", body: data})
    } catch (e) {
      console.error({ error: e.message })
    }
  }

  fileChange(event) {
    const {target} = event
    const {files} = target
    const filenameSpace = document.querySelector("span#file-name")
    const importButton = document.querySelector("button#import-button")

    if (filenameSpace) {
      filenameSpace.classList.remove("d-none")
      filenameSpace.classList.add("fst-italic")
      filenameSpace.innerHTML = `<span>${files[0].name}</span><span class="text-danger ms-5 fw-bold" style="cursor: pointer" data-action="click->containers#removeFile">x</span>`
    }

    if (importButton) importButton.classList.remove("disabled")
  }

  removeFile(event) {
    const filenameSpace = document.querySelector('span#file-name')
    const importButton = document.querySelector("button#import-button")

    if (filenameSpace.classList.contains("fst-italic")) {
      filenameSpace.innerHTML = ""
      filenameSpace.classList.add("d-none")
      filenameSpace.classList.remove("fst-italic")
    }

    if (importButton) importButton.classList.add("disabled")
  }

  async filter(event) {
    event.preventDefault()

    try {
      const {options} = event.params
      const url = new URL(location.href)

      if (!url.searchParams.has("options")) url.searchParams.append("options", options)
      else url.searchParams.set("options", options)

      const {ok} = await get(url, {responseKind: "turbo-stream"})

      if (ok) history.pushState({data_turbo_history: "replace"}, {}, url)
    } catch (e) {
      console.error({e})
    }
  }
}
