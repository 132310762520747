import { Controller } from "@hotwired/stimulus";
import mapbox from "mapbox-gl";
import * as turf from "@turf/turf";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import * as MapboxDrawWaypoint from 'mapbox-gl-draw-waypoint';
import { getAreaOfPolygon } from "geolib"

export default class extends Controller {
    static targets = ["editor", "table", "plotInfo", "controls", "toggleButtonText"];
    mapVisible = false;
    static values = {
        plots: Array,
        token: String,
        companyId: Number,
        translation: Object,
        // translationHeaderCode: String,
        // translationHeaderName: String,
        // translationHeaderArea: String,
        // translationInfo: String,
        // translationInfo2: String,
        // translationToggleMapView: String,
        // translationToggleTableView: String
    }

    updatedPlots = {}
    connect() {

        this.mapVisible = false;
    }

    toggle() {
        this.mapVisible = !this.mapVisible;
        if (this.mapVisible) {
            this.showMap()
        } else {
            this.showTable();
        }
    }

    showMap() {
        this.toggleButtonTextTarget.innerText = this.translationValue.toggle_table_view
        this.editorTarget.style.display = "block";
        this.tableTarget.style.display = "none";
        this.plotInfoTarget.style.display = "block";
        this.controlsTarget.classList.remove("hidden-map-controls");
        if (!this.map) {
            this.initMap()
        }

    }

    showTable() {
        this.toggleButtonTextTarget.innerText = this.translationValue.toggle_map_view
        this.editorTarget.style.display = "none";
        this.tableTarget.style.display = "block";
        this.plotInfoTarget.style.display = "none";
        this.controlsTarget.classList.add("hidden-map-controls");
    }

    initMap() {
        const mapCenter = this.plotsValue.find(plot => {
            return plot.entrance_point
        })

        mapbox.accessToken = this.tokenValue;
        this.map = new mapbox.Map({
            container: this.editorTarget,
            style: 'mapbox://styles/mapbox/satellite-streets-v12',
            center: [mapCenter?.entrance_point.longitude || 0, mapCenter?.entrance_point.latitude || 0] ,
            zoom: 9
        });

        let modes = MapboxDraw.modes;
        modes = MapboxDrawWaypoint.enable(modes);

        this.draw = new MapboxDraw({ modes, controls: {trash: true}, displayControlsDefault: false});

        this.map.addControl(this.draw);

        this.map.once("load", () => {
            this.drawPlots();
        })

        this.map.on('draw.update', this.updateArea.bind(this));
        this.map.on('draw.delete', this.updateArea.bind(this));
        this.map.on('draw.selectionchange', this.onSelectionChange.bind(this))
        this.map.on('contextmenu', this.deletePoint.bind(this))
    }

    onSelectionChange(event) {
        const currentPlot = this.plotsValue.find( p => p.id === event.features[0]?.properties?.productive_space_id);
        this.changePlotInfo(currentPlot)
    }

    changePlotInfo(plot) {
        if (plot) {
            this.plotInfoTarget.innerHTML = `
          <div>
            <p><strong>${this.translationValue.entity}:</strong> ${plot.entity?.name} ${plot.entity?.surname}</p>
            <p><strong>${this.translationValue.header_code}</strong> ${plot.code}</p>
            <p><strong>${this.translationValue.header_name}</strong> ${plot.name}</p>
            <p><strong>${this.translationValue.header_area}</strong> ${parseFloat(this.updatedPlots[plot.id]?.area ?? plot.area).toFixed(2) }</p>
          </div>
          `
        } else {
            this.plotInfoTarget.innerHTML = `<p>${this.translationValue.info}</p>`
        }
    }

    deletePoint(event) {
        const features = this.draw.getAll().features
        const selected = this.draw.getSelectedPoints()?.features?.at(0)
        if (!selected) {
            return
        }

        for (const f of features) {
            const coordinates = f.geometry.coordinates[0]
            const index = coordinates.findIndex( coord => {
                const lng = parseFloat(selected.geometry.coordinates[0])
                const lat = parseFloat(selected.geometry.coordinates[1])
                return lng === parseFloat(coord[0]) && lat === parseFloat(coord[1])
            })

            if (index >= 0) {
                coordinates.splice(index, 1)
                const areaCalculated = this.calculateArea((f.geometry.coordinates))
                this.updatedPlots[f.properties.productive_space_id] = { area: areaCalculated, bounds: coordinates}
                const currentPlot = this.plotsValue.find( p => p.id === f.properties?.productive_space_id);
                this.changePlotInfo(currentPlot);

                if (coordinates.length === 1) {
                    console.log(f.id)
                    this.draw.delete(f.id)
                } else {
                    this.draw.add({
                        ...f,
                        geometry: {
                            ...f.geometry,
                            coordinates: [coordinates]
                        }
                    })
                }

                break;
            }
        }
    }

    calculateArea(coordinates= []) {
        return getAreaOfPolygon(coordinates?.[0]) / 10000;
    }

    updateArea(e) {
        if (e) {
            const areaCalculated = this.calculateArea(e.features[0].geometry.coordinates)
            this.updatedPlots[e.features[0].properties.productive_space_id] = { area: areaCalculated, bounds: e.features[0].geometry.coordinates[0]}
            const currentPlot = this.plotsValue.find( p => p.id === e.features[0].properties.productive_space_id);
            this.changePlotInfo(currentPlot);
        }
    }
    getBounds() {
        let bounds = []

        this.plotsValue.forEach((plot) => {
            if (plot.bounds && plot.bounds.length) {
                plot.bounds.forEach((bound) => {
                    bounds.push([bound.longitude, bound.latitude])
                })
            }
        })

        return bounds
    }
    drawPlots() {
        if (this.plotsValue) {
            this.plotsValue.forEach((plot, index) => {
                if (plot.bounds.length) {
                    let initPoint, endPoint
                    const coordinates = plot.bounds.map((bound) => [bound.longitude, bound.latitude])

                    initPoint = [plot.bounds[0].longitude, plot.bounds[0].latitude]
                    coordinates.push(initPoint)

                    const feature = {
                        type: "Feature",
                        properties: {
                            productive_space_id: plot.id
                        },
                        geometry: {
                            type: "Polygon",
                            coordinates: [coordinates]
                        }
                    }
                //
                // if (!this.map.getSource(`plot_${plot.id}`)) {
                //     this.map.addSource(`plot_${plot.id}`, {
                //         type: "geojson",
                //         data: {type: "FeatureCollection", features: [feature]}
                //     })
                // }
                //
                // if (!this.map.getLayer(`plot_layer${plot.id}`)) {
                //     this.map.addLayer({
                //         id: `plot_layer${plot.id}`,
                //         type: "fill",
                //         // type: "line",
                //         source: `plot_${plot.id}`,
                //         layout: {
                //             visibility: "visible",
                //         },
                //         paint: {
                //             "fill-color": "yellow", "fill-opacity": 0.4,
                //         }
                //     })
                // }
                //
                // if (!this.map.getLayer(`plot_layer${plot.id}_limits`)) {
                //     this.map.addLayer({
                //         id: `plot_layer${plot.id}_limits`,
                //         type: "line",
                //         source: `plot_${plot.id}`,
                //         layout: {
                //             visibility: "visible",
                //             "line-join": "round", "line-cap": "round"
                //         },
                //         paint: {
                //             "line-color": "yellow", "line-width": 2
                //         }
                //     })
                // }

                    this.draw.add(feature);
                }
            })

        }
    }

    async saveCoordinates() {
        try {
            const response = await fetch(`/companies/${this.companyIdValue}/productive_spaces/update_coordinates_only.json`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                },
                body: JSON.stringify(({ plots: this.updatedPlots }))
            });
            if (!response.ok) throw new Error('ERROR')


            const updatedData = await response.json();
            this.plotsValue = updatedData.plots;

            this.draw.changeMode("simple_select")

        } catch (error) {
            console.error('Error', error);
        }
    }

    cancelOperation() {
        this.updatedPlots = {};
        this.showTable();
    }

    clearMap() {
        this.plotsValue.forEach((plot) => {
            if (this.map.getSource(`plot_${plot.id}`)) {
                this.map.removeLayer(`plot_layer${plot.id}`);
                this.map.removeLayer(`plot_layer${plot.id}_limits`);
                this.map.removeSource(`plot_${plot.id}`)
            }
        })
    }

}